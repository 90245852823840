import axios from "axios";

const API_URL = `${process.env.VUE_APP_BASE_API_URL}usergift`;
const API_ENDPOINT_CREATEGIFT = '/create'
const API_ENDPOINT_CLAIMGIFT = '/claim'
const API_ENDPOINT_GETGIFTCLIENT = '/giftclient'
const API_ENDPOINT_GETUSERGIFT = '/usergift'
const API_ENDPOINT_GETUSERGIFTS = '/mygifts'
const API_ENDPOINT_GETUSERCLAIMS = '/myclaims'
const API_ENDPOINT_RESENDGIFT = '/resendgift'
const API_ENDPOINT_GETASSETS = '/getassets'
const API_ENDPOINT_GETARKANEASSETS = '/getarkaneassets'
const API_ENDPOINT_GETASSETBYID = '/getassetbyid'

export default class UserGiftService {
    constructor() {

    }

    createUserGift(model) {
        const url = API_URL + API_ENDPOINT_CREATEGIFT
        return axios
            .post(url, model)

    }

    claimGift(model) {
        const url = API_URL + API_ENDPOINT_CLAIMGIFT
        return axios
            .post(url, model)
    }

    getGiftClient(id) {
        const url = `${API_URL}${API_ENDPOINT_GETGIFTCLIENT}?id=${id}`;
        return axios.get(url)
    }
    getUserGift(id) {
        const url = `${API_URL}${API_ENDPOINT_GETUSERGIFT}?id=${id}`;
        return axios.get(url)
    }
    getUserGifts(pageNo,pageSize){
        const url = `${API_URL}${API_ENDPOINT_GETUSERGIFTS}?userID=${localStorage.getItem("userGuid")}&pageNo=${pageNo}&pageSize=${pageSize}`;
        return axios.get(url)
    }
    getUserClaims(pageNo,pageSize){
        const url = `${API_URL}${API_ENDPOINT_GETUSERCLAIMS}?userID=${localStorage.getItem("userGuid")}&pageNo=${pageNo}&pageSize=${pageSize}`;
        return axios.get(url)
    }

    resendGift(model) {
        const url = API_URL + API_ENDPOINT_RESENDGIFT
        return axios
            .post(url, model)

    }

    getAssets(waxAccountName){
        const url = `${API_URL}${API_ENDPOINT_GETASSETS}?waxAccountName=${waxAccountName}`;
        return axios.get(url)
    }
    getArkaneAssets(ethAddress){
        const url = `${API_URL}${API_ENDPOINT_GETARKANEASSETS}?ethAddress=${ethAddress}`;
        return axios.get(url)
    }
    getAssetByID(assetID,templateID,assetType){
        const url = `${API_URL}${API_ENDPOINT_GETASSETBYID}?assetID=${assetID}&templateID=${templateID}&assetType=${assetType}`;
        return axios.get(url)
    }
}