<template>
  <div>
    <div id="login-div" class="login-button"></div>
    <!-- <p>ual login</p> -->
  </div>
</template>

<script>
import { UALJs } from "ual-plainjs-renderer";
import axios from "axios";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import WaxService from "../core/services/waxjs-service.js";
import { ASSETTYPE } from '../core/models/constants'

var waxMainnet = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "api.waxsweden.org",
      port: 443,
    },
  ],
};

export default {
  name: "Uallogin",
  data() {
    return {
      waxAccountName: "",
      anchor: new Anchor([waxMainnet], { appName: "giftnft" }),
      wax: new Wax([waxMainnet]),
      ual: null,
      arrayofUsers: [],
      authUser: null,
      view: null,
      waxPermission:'active'
    };
  },
  methods: {
    async login(view) {
      this.view = view;
      if (this.view == "Claim") {
        let waxService = new WaxService();
        this.waxAccountName = await waxService.login();
        this.$emit("update-accountname", this.waxAccountName);
      } else {
        if (this.waxAccountName) {
          this.$emit("update-accountname", this.waxAccountName);
          if (view && this.$route.name != "Gift") {
            //this.$router.push(this.view);
            this.$router.push({
              path: this.view,
              query: this.$route.query,
            });
          }
        } else {
          var elem = document.getElementById("ual-button");
          elem.click();
        }
      }
    },
    getWaxAccountName() {
      this.$emit("update-accountname", this.waxAccountName);
    },
    logout() {
      this.waxAccountName = "";
      this.$emit("update-accountname", this.waxAccountName);
      localStorage.removeItem("ual-session-expiration");
      localStorage.removeItem("userGuid");
      localStorage.removeItem("isTwitterConnected");
      localStorage.removeItem("twitterScreenName");
      localStorage.setItem("ual-wax:autologin", null);
      if (this.$route.name == "Gift") {
        this.$router.push({
          path: `/`,
          query: { id: this.$route.query.id },
        });
      }
    },
    async transact(data) {
        const actions = this.getTransactionActions(data)
        let result = await this.authUser.signTransaction(
            { actions: actions },
            { blocksBehind: 3, expireSeconds: 1200, broadcast: true }
        );
      if (result) {
        return result.transactionId;
      }
    },
    getTransactionActions(data) {
      let actions = [];
      if (data.assetType == ASSETTYPE.AA) {
        data.assets.forEach((e) => {
          actions.push({
            account: "atomicassets",
            name: "transfer",
            authorization: [
              {
                actor: this.waxAccountName,
                permission: this.waxPermission,
              },
            ],
            data: {
              from: this.waxAccountName,
              to: `${process.env.VUE_APP_WAX_ACCOUNT}`,
              asset_ids: [`${e.AssetID}`],
              memo: "",
            },
          });
        });
      } else {
        data.assets.forEach((e) => {
          actions.push({
            account: "simpleassets",
            name: "transfer",
            authorization: [
              {
                actor: this.waxAccountName,
                permission: this.waxPermission,
              },
            ],
            data: {
              from: this.waxAccountName,
              to: `${process.env.VUE_APP_WAX_ACCOUNT}`,
              assetids: [`${e.AssetID}`],
              memo: "",
            },
          });
        });
      }

      return actions;
    },
  },
  mounted() {
    let myAppName = "Gift NFT";
    this.anchor = new Anchor([waxMainnet], { appName: myAppName });
    this.wax = new Wax([waxMainnet]);

    let myAppRoot = {
      containerElement: document.getElementById("login-div"),
    };

    let authenticators = [];

    if (this.$route.name != "Claim") {
      authenticators.push(this.anchor);
    }
    authenticators.push(this.wax);
    this.ual = new UALJs(
      (arrayofUsers) => {
        if (arrayofUsers.length > 0) {
            if(arrayofUsers[0].session && arrayofUsers[0].session.auth.permission)
            {
                this.waxPermission = arrayofUsers[0].session.auth.permission;
                } else {
                    this.waxPermission = "active";
                }
          this.waxAccountName = arrayofUsers[0].accountName;
          this.authUser = arrayofUsers[0];
          // authenticate user on our server
          axios
            .post(`${process.env.VUE_APP_BASE_API_URL}members/authenticate`, {
              username: this.waxAccountName,
            })
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userGuid", response.data.userGuid);
                localStorage.setItem("isTwitterConnected", response.data.isTwitterConnected)
                localStorage.setItem("twitterScreenName", response.data.twitterScreenName)
                this.$emit(
                  "update-accountname",
                  arrayofUsers[0].accountName
                );
                if (this.$route.query.returnUrl) {
                  this.$router.push(this.$route.query.returnUrl);
                } else if (this.view) {
                  this.$router.push(this.view);
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      },
      [waxMainnet],
      myAppName,
      authenticators,
      myAppRoot
    );

    this.ual.init();
    document.getElementById("ual-button").style.display = "none";
  },
};
</script>

// <style scoped lang="scss">
// #ual-modal {
//   z-index: 9999;
// }
// </style>
