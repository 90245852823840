<template>
	<div>
		<nav class="navbar" role="navigation" aria-label="main navigation">
			<div class="container is-max-desktop">
				<div class="navbar-brand">
					<a class="navbar-item" :href="getUrl()">
						<img
							v-if="!theme"
							src="@/assets/img/logo.svg"
							width="138"
							height="48"
							alt="GiftNFT"
						/>
						<img
							v-if="theme == 'gyb'"
							src="@/assets/img/gyb-themed-logo.svg"
							width="138"
							height="48"
							alt="GiftNFT"
						/>
					</a>

					<a
						@click="toggleMobileMenu"
						role="button"
						class="navbar-burger burger"
						:class="{ 'is-active': mobileMenuOption }">
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>

				<div class="navbar-menu" :class="{ 'is-active': mobileMenuOption }">
					<div class="navbar-end"  v-if="isValidClient">
						<div class="navbar-item" v-if="allowGiftNft">
							<a @click="childlogin(`/gift`)">Gift A NFT</a>
						</div>

						<nav-dropdown
							v-if="accountName && domainSettings"
							:account-name="accountName"
							:show-my-gifts="allowGiftNft"
                            :domain="domain"
                            :domainSettings="domainSettings"
							@logout="logout"
                            @toggleMobileMenu="toggleMobileMenu"
						/>

						<div v-if="!accountName" class="navbar-item">
							<div class="buttons">
								<a
									class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold"
									@click="childlogin('')">
									Login
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<Uallogin v-if="domain == DOMAIN.WAX" ref="uallogin" @update-accountname="updateAccountName"></Uallogin>
        <GybLogin v-if="domain == DOMAIN.GYB" ref="gyblogin" @update-accountname="updateAccountName">
		</GybLogin>
		<router-view v-if="domainSettings" ref="templateData" :domain="domain" :domainSettings="domainSettings" :accountName="accountName" :isValidClient="isValidClient" :giftClient="giftClient" @update-clientstatus="updateClientStatus"></router-view>
		<footer-section v-if="domainSettings" :domain="domain" :domainSettings="domainSettings"></footer-section>
		<div class="milky-way"></div>
		<div class="milky-way secondary"></div>
	</div>
</template>

<script>
import axios from "axios";
import FooterSection from "@/components/Footer.vue";
import Uallogin from "@/components/UalLogin.vue";
import UserGiftService from "./core/services/user-gift.service";
import NavDropdown from '@/components/NavDropdown.vue';
import GybLogin from '@/components/GybLogin.vue';
import { DOMAIN } from './core/models/constants'

export default {
    components: {
        FooterSection,
        Uallogin,
        NavDropdown,
        GybLogin
    },
    data() {
        return {
            accountName: "",
            mobileMenuOption: false,
            isValidClient: true,
            allowGiftNft: true,
            giftClient: null,
            domain: DOMAIN.GYB,
            domainSettings: null,
            DOMAIN:DOMAIN,
            loadChildComponent:false,
			theme: ''
        };
    },
    methods: {
        toggleMobileMenu() {
            this.mobileMenuOption = !this.mobileMenuOption;
        },
         async childlogin(view) {
            this.toggleMobileMenu()
            if(this.domain == DOMAIN.WAX){
                await this.$refs.uallogin.login(view);
            }
            else{
                this.$refs.gyblogin.openLoginModal(view);
            }
        },
        updateAccountName(e) {
            this.accountName = e
        },
        updateClientStatus(e){
            this.isValidClient = e
        },
        async transact(data) {
            if(this.domain == DOMAIN.WAX){
                return await this.$refs.uallogin.transact(data);
            }
            else{
                return await this.$refs.gyblogin.transferNFT(data);
            }
        },
        logout(){
            if(this.domain == DOMAIN.WAX){
                this.$refs.uallogin.logout();
            }
            else{
                this.$refs.gyblogin.logout();
            }

        },
        getUrl(){
            if(this.$route.query.id){
                return `/?id=${this.$route.query.id}`
            }
            else if(this.$route.params.giftclientid){
                return `/?id=${this.$route.params.giftclientid}`
            }
            else{
                return '/'
            }
        },
    },
    mounted() {
		//for claim and myclaim pages, we dont need to check for valid client, and hide gift nft button
		let service = new UserGiftService();
        if(this.$route.name == 'Claim' || this.$route.name == 'MyClaims'){
            this.isValidClient = true
			this.allowGiftNft = true
			if(this.$route.params.giftclientid){
				service.getGiftClient(this.$route.params.giftclientid).then((res) => {
					if(res.data.isSuccess) // if not a registered client
					{
						this.isValidClient = true
						this.giftClient = res.data
                    }
                    else{
                        this.giftClient = null
                    }
				})
			}
        }
        else{
            if(this.$route.query.id){
                 service.getGiftClient(this.$route.query.id).then((res) => {
				if(res.data.isSuccess) // if registered client
				{
					this.isValidClient = true
					this.giftClient = res.data
					//this.$emit("update-clientstatus", true);
				}
				else{// if not a registered client
					this.$router.push('/')
				}
				})
				.catch((error) => {
					console.log(error)
					this.$router.push('/')
				});
            }
             this.loadChildComponent = true

        // if(!this.$route.query.id){
        //     if(this.$route.name != 'Home')
        //     this.$router.push('/')
        // }
        // else{
        //     service.getGiftClient(this.$route.query.id).then((res) => {
		// 		if(res.data.isSuccess) // if registered client
		// 		{
		// 			this.isValidClient = true
		// 			this.giftClient = res.data
		// 			//this.$emit("update-clientstatus", true);
		// 		}
		// 		else{// if not a registered client
		// 			this.$router.push('/')
		// 		}
		// 		})
		// 		.catch((error) => {
		// 			console.log(error)
		// 			this.$router.push('/')
		// 		});
		// 	}
        }
    },
    created(){
        axios.get(`${process.env.VUE_APP_BASE_API_URL}members/domain`).then((res) =>{
			this.domainSettings = JSON.parse(res.data.settings)
			this.domain = res.data.id

			// SET DOMAIN = WAX
			//this.domainSettings = JSON.parse('{"Urls":["wax.giftnft.io"],"Theme":"","WebsiteName":"wax","WebsiteUrl":"https://wax.giftnft.io","ExplorerUrl":"https://wax.atomichub.io/explorer","ImageExplorerUrl":"https://cloudflare-ipfs.com/ipfs","TxEndpoint":"https://api.wax.alohaeos.com","TxExplorerUrl":"https://wax.bloks.io/transaction","ClaimUrl":"https://wax.giftnft.io/claim","SendGridTemplate":"d-93ade29307164e098796ea7be9d344b0","ExpirationDays":30}')
			//this.domain = DOMAIN.WAX

			// SET DOMAIN = GYB
			//this.domainSettings = JSON.parse('{"Urls":["gyb.giftnft.io"],"Theme":"","WebsiteName":"gyb","WebsiteUrl":"https://gyb.giftnft.io","ExplorerUrl":"https://testnets.opensea.io","ImageExplorerUrl":"https://cloudflare-ipfs.com/ipfs","TxEndpoint":"https://api-staging.arkane.network/api/transactions/execute","TxExplorerUrl":"https://rinkeby.etherscan.io/tx","ClaimUrl":"https://gyb.giftnft.io/claim","SendGridTemplate":"d-73ff1e733d734edda48d99634d1a61b2","ExpirationDays":30}')
			//this.domain = DOMAIN.GYB

			// for WAX load original default theme
			if(this.domain != DOMAIN.WAX) {
				this.theme = this.domainSettings.WebsiteName
			}
			// set theme
			if (this.theme) {
				let htmlElement = document.documentElement
				htmlElement.setAttribute('theme', this.theme);
			}
        })
    }
};
</script>
