<template>
	<footer>
		<div class="container is-max-desktop">
			<div class="columns is-vcentered is-gapless is-mobile is-multiline">
				<div class="column is-full-mobile is-5-tablet">
					<div class="navbar-menu is-active">
						<div class="navbar-start">
							<a target="_blank" href="https://app.bountyblok.io/terms" class="navbar-item">Terms & Conditions</a>
							<a target="_blank" href="https://app.bountyblok.io/privacy" class="navbar-item">Privacy</a>
							<!-- <a class="navbar-item">Support</a> -->
						</div>
					</div>
					<div class="copyright">Copyright © 2021 bountyblok.io</div>
				</div>
				<div class="column is-full-mobile is-7-tablet">
					<div class="partners">
						<a :href="domainSettings.ExplorerUrl" target="_blank">
							<img :src="getExplorerImg()" alt="AtomicHub"/>
						</a>
						<a :href="getUrl()" target="_blank">
							<img :src="getImg()" alt="WAX"/>
						</a>
						<a href="https://www.bountyblok.io" target="_blank">
							<img src="@/assets/img/bountyblok-logo-white.svg" alt="bountyblok"/>
						</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { DOMAIN } from '../core/models/constants'

export default {
		props:{
        domain:{
             type: Number,
             required: true
        },
        domainSettings:{
            type: Object
        }
    },
    data() {
        return {
			DOMAIN:DOMAIN
		}
	},
	methods:{
		getUrl(){
			let url = null
			switch(this.domain){
				case DOMAIN.WAX:
					url = `https://wax.io`
					break;
				case DOMAIN.GYB:
					url = `https://arkane.network/`
			}
			return url;
		},
		getImg(){
			switch(this.domain){
				case DOMAIN.WAX:
					return require(`@/assets/img/wax-logo-white.svg`)
				case DOMAIN.GYB:
					return require(`@/assets/img/arkane-logo-white.png`)
			}
		},
		getExplorerImg(){
			switch(this.domain){
				case DOMAIN.WAX:
					return require(`@/assets/img/atomichub-logo-white.svg`)
				case DOMAIN.GYB:
					return require(`@/assets/img/opensea-logo-flat-colored-blue.svg`)
			}
		}
	}
}
</script>

<style scoped lang="scss">
footer {
    position: relative;
    margin-top: 120px;
    padding: 20px 0;
    border-top: 1px solid black;

    @media screen and (min-width: $tablet) and (max-width: $desktop) {
        padding: 20px;
    }

    &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 220px;
        background: url('~@/assets/img/footer-bg.png');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100% 220px;
        opacity: 0.6;
        mix-blend-mode: overlay;
	}

	.navbar-menu {
		// display: flex;
		margin-bottom: 5px;

		@media screen and (max-width: 766px) {
			.navbar-start {
				width: 100%;
			}
		}

		.navbar-item {
			padding: 0;
			background: transparent;
			color: $link;

			@media screen and (max-width: 766px) {
				width: 100%;
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;
			}

			+ .navbar-item {
				@media screen and (min-width: $tablet) {
					padding-left: 15px;
				}
			}
		}
	}

    .copyright {
		color: rgba($white, 0.5);
		font-size: 12px;

        @media screen and (max-width: 766px) {
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .partners {
        text-align: right;

        @media screen and (max-width: 766px) {
            text-align: center;
        }

        a {
            display: inline-block;
            transition: all .2s linear;

            + a {
                margin-left: 30px;
            }

            @media screen and (max-width: 766px) {
                margin: 0 10px!important;
            }

			&:hover {
				transform: translateY(1px);
            }

            img {
                max-height: 24px;
                vertical-align: middle;

                @media screen and (max-width: 766px) {
                    max-height: 21px;
                }
            }
        }
    }
}
</style>