<template>
	<div class="claim">
		<div class="container is-max-desktop">
			<div class="columns is-full-height is-centered is-gapless is-mobile is-desktop is-vcentered">
				<div class="column is-10-tablet is-11-desktop">
					<div class="columns is-vcentered is-mobile is-multiline">
						<div class="column is-full-mobile is-half-tablet is-5-desktop">
							<div class="gift-preview">
								<div class="header">
									<div class="columns is-gapless is-mobile">
										<div class="column">
											<img
												v-if="theme != 'gyb'"
												src="@/assets/img/logo.svg"
												width="78"
												height="28"
												alt="GiftNFT"
											/>
											<img
												v-if="theme == 'gyb'"
												src="@/assets/img/gyb-themed-logo.svg"
												width="78"
												height="28"
												alt="GiftNFT"
											/>
										</div>
										<div class="column">
											<i class="far fa-badge-check"></i>
										</div>
									</div>
								</div>
								<div class="asset-preview">
									<img :src="assetImgUrl" />
									<div v-if="assetTemplateMint || assetName" class="asset-name">
										<div class="columns is-mobile is-gapless">
											<div v-if="assetTemplateMint" class="column is-narrow">
												<div class="num" v-text="'#' + assetTemplateMint"></div>
											</div>
											<div v-if="assetName" class="column">
												<div class="name" v-text="assetName"></div>
											</div>
										</div>
									</div>
								</div>
                                <div class="msg-box">
									<p v-text="userGift.message"></p>
								</div>
								<!-- <div class="msg-box">
									<div class="has-text-weight-bold">Transaction Id:</div>
									<div>{{ userGift.message }}</div>
								</div> -->
							</div>
						</div>

						<div class="column is-full-mobile is-half-tablet is-7-desktop">

                            <!-- Confirmation Modal Starts-->
							<div class="confirm-send modal" :class="{ 'is-active' : showConfirmModal}">
								<div class="modal-background" @click="closeConfirmModal()"></div>
								<div class="modal-card">
									<header class="modal-card-head">
										<p class="modal-card-title has-text-weight-bold">
											Claim NFT
										</p>
										<button
											@click="closeConfirmModal()"
											class="delete"
											aria-label="close">
										</button>
									</header>
									<section class="modal-card-body">
										<div v-if="domain == DOMAIN.WAX">
                                            <p v-if="!giftClient"><strong>To 'claim' your NFT, simply login to your WAX account using the button below.</strong> Note, if you don't have one, you'll be given the option to create an account.</p>
											<p v-if="giftClient && !giftClient.claimConfirmationModalText"><strong>To 'claim' your NFT, simply login to your WAX account using the button below.</strong> Note, if you don't have one, you'll be given the option to create an account.</p>
                                            <p v-if="giftClient && giftClient.claimConfirmationModalText">{{giftClient.claimConfirmationModalText}}</p>
										</div>
										<div v-else-if="domain == DOMAIN.GYB">
											<p v-if="!giftClient"><strong>To 'claim' your NFT, simply login to your ARKANE account using the button below.</strong> Note, if you don't have one, you'll be given the option to create an account.</p>
											<p v-if="giftClient && !giftClient.claimConfirmationModalText"><strong>To 'claim' your NFT, simply login to your ARKANE account using the button below.</strong> Note, if you don't have one, you'll be given the option to create an account.</p>
                                            <p v-if="giftClient && giftClient.claimConfirmationModalText">{{giftClient.claimConfirmationModalText}}</p>
										</div>
									</section>
									<footer class="modal-card-foot">
										<button
											class="button is-primary is-rounded is-outlined is-uppercase has-text-weight-bold"
											@click="claim()">
											{{ getClaimBtnText() }}
										</button>
									</footer>
								</div>
							</div>
                            <!-- Confirmation Modal Ends-->

							<div v-if="giftID && !giftClaimed" class="claim-nft content">
								<h2>You have been gifted this awesome NFT, enjoy!</h2>
								<div class="sent-from">
									<p><span>To:</span>{{ userGift.toEmail }}{{ userGift.toPhone }}</p>
									<p><span>From:</span>{{ userGift.fromName }}</p>
								</div>
								<label class="label">Message</label>
								<p v-if="giftClient && giftClient.staticEmailMessage" v-text="giftClient.staticEmailMessage" class="is-italic"></p>
                                <p v-text="userGift.message" class="is-italic"></p>
								<button
									@click="showConfirmationModal()"
									class="send-btn button is-medium is-rounded is-link">
									Claim NFT
								</button>
							</div>

							<div v-if="giftClaimed" class="claim-nft content">
								<h2 v-if="isSuccess">Gift has been claimed!</h2>
								<h2 v-if="!isSuccess">Oops!</h2>

								<p v-if="isSuccess">
									You have successfully claimed the gift from
									<strong>{{ userGift.fromName }}</strong>
                                    on <strong>{{ giftClaimDate | moment("MMM Do, YYYY") }}</strong>!
									<br>
									<a target="_blank" :href="waxTxUrl">View the blockchain transaction here</a>
								</p>

								<!-- <p v-if="isSuccess" class="has-text-weight-bold">
                                    <a target="_blank" :href="waxTxUrl">View the blockchain transaction here</a>
								</p> -->

								<a
									v-if="isSuccess && !isPack"
									class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold"
									target="_blank"
									:href="getExplorerUrl()">
									See My Collections
								</a>

								<a
									v-if="isSuccess && isPack"
									class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold"
									target="_blank"
									:href="giftClient.unpackUrl">
									Click To Open
								</a>

								<p v-if="!isSuccess">
									{{ errorMessage }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
	<div v-if="!waxAccountName" id="login-div"></div>
	<button v-if="!giftClaimed" type="button" @click="claim()">Claim</button>
	<p v-if="giftClaimed">Gift successfully claimed. Visit this link to see your gift</p>
	<a  v-if="giftClaimed" href="https://bcheros.com">Click here</a> -->
</template>

<script>
import UserGiftService from "../core/services/user-gift.service";
import UserClaimModel from "../core/models/user-claim.model";
import { ASSETTYPE,DOMAIN } from '../core/models/constants'

export default {
	props: {
        accountName: {
			type: String,
			required: true
        },
        giftClient:{
            type: Object,
        },
        domain:{
             type: Number,
             required: true
        },
        domainSettings:{
            type: Object
        }
    },
	watch: {
		accountName: function() {
			if (this.claimGift) {
				this.transfer(true);
			}
        },
        giftClient:function(){
        //   if(this.userGift.assetID){
        //     this.setIsPack(this.userGift.assetID,this.giftClient.allowedTemplates)
        //   }
        }
	},
	data() {
		return {
			errors: [],
			message:
				"A gift from me, to you! I really hope you enjoy this NFT. Cheers!",
			assetImgUrl: require("@/assets/img/card-placeholder.jpg"),
			assetTemplateMint: "",
			assetName: "",
			giftID: null,
			giftClaimed: false,
			userGift: {},
			isSuccess: false,
			errorMessage: null,
            claimGift: false,
            waxTxUrl: null,
            giftClaimDate:null,
            waxOpenPackUrl: null,
            atomicHubInventory:"https://wax.atomichub.io/profile/",
            simpleAssetInventory:"https://wax.simplemarket.io/explorer/",
			isPack:false,
            showConfirmModal:false,
            confirmationModalText:"To 'claim' your NFT, simply login to your WAX account using the button below. Note, if you don't have one, you'll be given the option to create an account.",
			assetType: ASSETTYPE.AA,
			DOMAIN:DOMAIN,
			theme: this.domainSettings.WebsiteName
		};
	},
	methods: {
        setIsPackByCollection(collection, allowedCollection){
            if(!collection || !allowedCollection){
                return;
            }
            if(collection == allowedCollection){
                this.isPack = true;
            }
		},
        setIsPackByTemplate(templateID, templates){
            if(!templates || !templateID){
                return;
            }
            let templatesArr = templates.split(',')
              let index = templatesArr.indexOf(templateID)
              if(index >= 0){
                this.isPack = true
              }
		},
		showConfirmationModal(){
			this.showConfirmModal = true;
		},
		async claim() {
			this.showConfirmModal = false;
			this.claimGift = true;
			if (!this.accountName || !this.giftID) {
				await this.$parent.childlogin(this.$route.name);
			} else {
				this.transfer(true);
			}
		},
		transfer(transferAsset = false) {
			if (!transferAsset) {
				return;
            }
            if(!this.accountName){
                return;
            }
			let model = new UserClaimModel();
			model.ToAccount = this.accountName;
            model.GiftID = this.giftID;
            model.UserGuid = localStorage.getItem("userGuid");
			let service = new UserGiftService();
            this.claimGift = false;
			service
				.claimGift(model)
				.then((response) => {
					if (response.status == 200) {
                        this.giftClaimed = true;
                        console.log(response.data)
                        this.isSuccess = response.data.isSuccess;
                        this.waxTxUrl = `${this.domainSettings.TxExplorerUrl}/${response.data.transactionID}`;
                        this.giftClaimDate = response.data.claimDate
						this.errorMessage = response.data.errorMessage;
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},

        getAssetByID(assetID,templateID){
            let service = new UserGiftService();
            service.getAssetByID(assetID,templateID,this.assetType).then((response) =>{
                let data = response.data.asset
                this.assetImgUrl = data.imgUrl ? data.imgUrl : 'https://giftnft.s3.amazonaws.com/card-placeholder.jpg';
                this.assetName = data.name
                this.assetTemplateMint = data.templateMint
                if(this.domain == DOMAIN.WAX && this.assetType == ASSETTYPE.AA){
                    if(this.giftClient){
                        if(data.collectionName){
                            this.setIsPackByCollection(data.collectionName,this.giftClient.allowedCollection)
                        }
                        else if(data.templateID){
                            this.setIsPackByTemplate(data.templateID,this.giftClient.allowedTemplates)
                        }
                    }
                }
            })
        },
		closeConfirmModal() {
			this.showConfirmModal = false;
        },
        getExplorerUrl(){
            switch(this.domain){
				case DOMAIN.WAX:
					if(this.assetType == ASSETTYPE.AA){
						return `${this.atomicHubInventory}${this.accountName}#inventory`
					}
					else{
						return `${this.simpleAssetInventory}main?searchString=${this.accountName}`
					}
				case DOMAIN.GYB:
					return `${this.domainSettings.ExplorerUrl}/collections`
			} 
		},
		getClaimBtnText(){
			if(this.accountName){
				return 'Claim'
			}
			switch(this.domain){
				case DOMAIN.WAX:
					return 'Login To Wax'
				case DOMAIN.GYB:
					return 'Login To Arkane'
			} 
		}
	},
	mounted() {
		this.giftID = this.$route.params.giftid;
		if (this.giftID) {
			let service = new UserGiftService();
			service.getUserGift(this.giftID).then((res) => {
				if (res.status == 200) {
					// if(this.giftClient){
					// 	this.setIsPack(this.userGift.assetID,this.giftClient.allowedTemplates)
					// 	}
                    this.userGift = res.data;
                    this.assetType = res.data.assetType
                    // if(this.domain == DOMAIN.WAX){
                    // this.getAsset(res.data.assetID,res.data.assetType);
                    // }
                    // else{
                    //     this.getArkaneAsset(res.data.assetID,res.data.templateID)
                    // }
                    this.getAssetByID(res.data.assetID,res.data.templateID)
                    this.giftClaimed = res.data.isClaimed;

                    if(this.giftClaimed){
                    this.giftClaimDate = res.data.claimDate
                    this.waxTxUrl = `${this.domainSettings.TxExplorerUrl}/${this.userGift.transactionID}`;
                    this.isSuccess = true
                    //this.errorMessage = `This NFT has been claimed on ${ this.giftClaimDate}: TXurl`
                    }
				}
			});
		}
	},
};
</script>

<style scoped lang="scss">
$padding: 60px;

.claim {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		margin-top: $padding;
		margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		padding: 40px 0;
	}

	@media screen and (max-width: 766px) {
		overflow-x: hidden;
		overflow-y: visible;
		padding: 40px 30px;
	}
}

.columns.is-full-height {
	min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$padding});
}

.login-required {
	position: relative;

	h1,
	p {
		margin-bottom: 30px !important;
		color: $white;
	}

	p {
		margin-bottom: 35px !important;
	}
}

.gift-preview {
	position: relative;
	margin-right: 30px;
	overflow: hidden;
	background: $white;
	border-radius: 5px;
	box-shadow: $box-shadow-large;

	@media screen and (max-width: 766px) {
		margin-right: 0;
	}

	@media screen and (min-width: $tablet) and (max-width: $desktop) {
		margin-left: 30px;
		margin-right: 30px;
	}

	.header {
		$size: 30px;
		padding: 10px 20px;
		border-bottom: 1px solid $border;

		img {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			height: $size;
			margin-top: 1px;
		}

		i {
			float: right;
			font-size: 21px;
			color: $primary;
			line-height: $size;
		}
	}

	.asset-preview {
		padding: 30px 50px;
		text-align: center;
		background: $light;
		overflow: hidden;

		img {
			display: inline-block;
			vertical-align: middle;
			-webkit-filter: drop-shadow(0 2px 3px rgba(black, 0.3));
			filter: drop-shadow(0 2px 3px rgba(black, 0.3));
			// box-shadow: 0 2px 3px rgba(black, 0.3);
		}

		.asset-name {
			margin-top: 15px;
			background: $light;
			line-height: 1;

			.num,
			.name,
			.column {
				display: block;
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.num {
				padding-right: 10px;
				color: $text;
				text-align: left;
			}

			.name {
				padding-left: 10px;
				color: $primary;
				text-align: right;
			}
		}
	}

	.msg-box {
		padding: 15px 20px;
		border-top: 1px solid $border;
		color: $info;
		text-align: center;
		//text-transform: uppercase;

		div {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.claim-nft {
	@media screen and (max-width: 766px) {
		margin-top: 30px;
	}

	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	p {
		margin-bottom: 30px !important;
		color: $white;

		strong {
			color: $white;
		}
	}

	.label {
		margin-bottom: 5px;
		color: $white;
		line-height: 1;
	}

	button {
		display: inline-block;
		padding: 0px 30px;
		height: 46px;
		text-transform: uppercase;
		background: $gradient;
		background-repeat: no-repeat;
		font-weight: bold;
		font-size: $body-size;
		border: none;
		transition: all 0.2s linear;

		&:hover {
			border: none;
			background: $gradient;
			transform: translateY(1px);
		}
	}

	.sent-from {
		margin-bottom: 30px;

		p {
			margin: 0 !important;
			text-transform: uppercase;

			span {
				display: inline-block;
				width: 80px;
				font-weight: bold;
				color: $link;
			}
		}
	}
}
</style>